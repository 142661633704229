@import '../../../../variables.css';

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    padding: 68px 0;
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
}

.container {
    padding: 40px 16px;
}

.mainTitle {
    margin-bottom: 36px;
    font-size: var(--ark-48-font-size);
    font-weight: var(--ark-font-weight-bold);
    line-height: var(--ark-56-line-height);
}

.subtitle {
    margin-top: 40px;
    font-size: var(--ark-24-font-size);
}

.text {
    margin-bottom: 25px;
    font-size: var(--ark-16-font-size);
    line-height: 1.3;
    max-width: 90%;
    text-align: justify;
}

.CTA button {
    margin: 0 auto;
}

.CTA {
    padding-top: 10px;
}

.highlighted {
    color: #9C237A;
    font-size: var(--ark-48-font-size);
    font-weight: var(--ark-font-weight-bold);
}

.list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.panel {
    width: 292px;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    border-radius: 16px;
}

.panel img {
    width: 100%;
    border-radius: 0 0 16px 16px;
}

.caption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
}

.count {
    font-size: var(--ark-36-font-size);
    line-height: var(--ark-48-line-height);
}

.type {
    font-size: var(--ark-18-font-size);
    line-height: var(--ark-25-line-height);
}

.specialText {
    font-size: var(--ark-28-font-size);
    line-height: var(--ark-34-line-height);
    font-weight: var(--ark-font-weight-bold);
}

@media screen and (max-width: var(--ark-medium-desktop-figma-1366)) {
    .caption {
        padding: 22px 0;
    }

    .panel {
        width: 200px;
        height: 237px;
    }

    .count {
        font-size: var(--ark-26-font-size);
        line-height: var(--ark-36-line-height);
    }

    .type {
        font-size: var(--ark-16-font-size);
        line-height: var(--ark-22-line-height);
    }
}

@media screen and (max-width: var(--ark-large-mobile-768)) {
    .content {
        padding: 20px 0;
        background-size: contain;
    }

    .container {
        padding: 32px 16px;
    }

    .mainTitle {
        margin-bottom: 36px;
        font-size: var(--ark-26-font-size);
        line-height: var(--ark-36-line-height);
    }

    .subtitle {
        margin-top: 36px;
        font-size: var(--ark-22-font-size);
        line-height: var(--ark-31-line-height);
    }
}

@media screen and (max-width: 430px) {
    .mainTitle {
        width: 288px;
        margin: auto;
        margin-bottom: 36px;
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-34-line-height);
    }

    .subtitle {
        width: 288px;
        margin: 36px auto auto auto;
        font-size: var(--ark-20-font-size);
        line-height: var(--ark-28-line-height);
    }

    .caption {
        padding: 24px 23px;
    }

    .panel:nth-child(even) .caption {
        grid-column: 2;
    }

    .panel {
        width: 288px;
        height: 127px;
        grid-template-columns: 1fr auto;
    }

    .panel img {
        width: 144px;
        height: 100%;
        object-fit: cover;
        border-radius: 0 16px 16px 0;
    }

    .panel:nth-child(even) img {
        grid-column: 1;
        grid-row: 1;
        border-radius: 16px 0 0 16px;
    }

    .count {
        font-size: var(--ark-24-font-size);
        line-height: var(--ark-34-line-height);
    }
}
